import {Component, OnInit, VERSION} from '@angular/core';
import { Router } from '@angular/router';
import {environment} from '../environments/environment';

/** Class for the app component that is bootstrapped to run the application
 */
@Component({
	selector: 'body',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	/** the Angular version */
	version = VERSION.full;
	/** whether we are production or not */
	envProd = environment.production;
	constructor(private router: Router) {}
	ngOnInit() {
		const agent = window.navigator.userAgent.toLowerCase();
		if (agent.indexOf('trident') > -1) { this.router.navigate(['browser-not-supported']); }
	}
}
