import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../environments/environment';

/** This component handles redirecting users to the auth provider */
@Component({
  template: `
			<h3 style="font-size: 16px">Redirecting to login server</h3>
	`
})
export class LoginComponent implements OnInit {
  /** store adfs client id */
  private clientId = environment.clientId;
  /** store redirect url */
  private redirectUrl = environment.redirectUri;
  /** store adfs resource */
  private resource = environment.resource;
  /** store oauthTokenUri resource */
  private oauthTokenUri = environment.oauthTokenUri;

  /** the configured URL endpoint to send the user's browser to for token negotiation */
  _endPoint: string;
  /** reference to the window object */
  _window: Window;

  /** Creates a new instance of the LoginComponent, makes available
	 * a DOCUMENT reference and instantiates required variables
	 *
	 * @param document angular provided reference to the current document
	 */
  constructor(@Inject(DOCUMENT) private document: Document) {
      this._window = this.document.defaultView;
      this._endPoint = this.oauthTokenUri +
          '?client_id=' + this.clientId +
          '&resource=' + this.resource +
          '&response_type=token' +
          '&redirect_uri=' + this.redirectUrl + 'oauth';
  }

  /** once the component is up and running, redirect the user to the auth provider */
  ngOnInit() {
      this._window.location.href = this._endPoint;
  }
}
