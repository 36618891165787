import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'browser-not-supported',
	templateUrl: './browser-not-supported.component.html',
	styleUrls: ['./browser-not-supported.component.scss']
})
export class BrowserNotSupportedComponent implements OnInit {

	constructor(private router: Router) { }

	ngOnInit() {
	const agent = window.navigator.userAgent.toLowerCase();
	if (agent.indexOf('trident') > -1) {} else { this.router.navigate(['']); }
	}
}
