// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep div .ui-dialog .ui-dialog-titlebar {
  border: 2px solid #A7A7A7;
}

:host ::ng-deep div .ui-dialog .ui-dialog-content {
  border: 2px solid #A7A7A7;
  background-color: darkgreen;
  color: white;
}

td[colspan]:hover {
  background: white;
  color: #1f7307;
  font-weight: bold;
}

.style1.warning {
  font-color: red;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 0px;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}`, "",{"version":3,"sources":["webpack://./src/app/alpha-beta-failure-trend/alpha-beta-failure-trend.component.scss"],"names":[],"mappings":"AAEA;EACC,yBAAA;AADD;;AAIA;EACC,yBAAA;EACA,2BAAA;EACA,YAAA;AADD;;AAIA;EACC,iBAAA;EACA,cAAA;EACA,iBAAA;AADD;;AAIA;EACC,eAAA;AADD;;AAIA;EACC,gBAAA;EACA,YAAA;EACA,uBAAA;EACA,gBAAA;EACA,cAAA;EACA,mBAAA;EACA,2BAAA;AADD","sourcesContent":["\n\n:host ::ng-deep div .ui-dialog .ui-dialog-titlebar{\n\tborder : 2px solid #A7A7A7;\n}\n\n:host ::ng-deep div .ui-dialog .ui-dialog-content{\n\tborder : 2px solid #A7A7A7;\n\tbackground-color: darkgreen;\n\tcolor:white;\n}\n\ntd[colspan]:hover {\n\tbackground: white;\n\tcolor: #1f7307;\n\tfont-weight: bold;\n}\n\n.style1.warning{\n\tfont-color: red;\n}\n\n.p-datatable .p-datatable-thead > tr > th {\n\ttext-align: left;\n\tpadding: 0px;\n\tborder-width: 0 0 1px 0;\n\tfont-weight: 600;\n\tcolor: #495057;\n\tbackground: #f8f9fa;\n\ttransition: box-shadow 0.2s;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
