/**This file contains a constant object that has properties that are necessary
 * for the build found in the `development` configuration in `angular.json`.
 */
export const environment = {
	apiTestingEnabled: true,
	production: false,
	backend_url: 'https://api.d01e.gcp.ford.com/dp',
	oauthTokenUri: 'https://corpqa.sts.ford.com/adfs/oauth2/authorize',
  clientId: 'urn:dp_adfs:clientid:web_dp_adfs:dev',
  resource: 'urn:dp_adfs:resource:web_dp_adfs:dev',
  redirectUri: 'https://wwwqa.dp.ford.com/',
	version: '1.20.2',

	baseUrl: 'https://api.d01e.gcp.ford.com/dp',
	openidUrl: 'https://corpqa.sts.ford.com/adfs/oauth2',
	openidClientId: 'urn:dp_adfs:clientid:web_dp_adfs:dev',
	openidResource: 'urn:dp_adfs:resource:web_dp_adfs:dev'
	// include development api base url string here as a property
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
