import { Injectable } from '@angular/core';
import {ID_TOKEN_JWT, ID_TOKEN_USERID, TOKEN_EXPIRATION_TIME, EMAIL} from './auth-vars-constants';
import * as  moment from 'moment';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }
	/**
	 * Retrieve user's CDSID
	 */
	static seconds = interval(2000);
	static subscription ;
	static loginID = new Subject<string>();


	static retrieveCDSID(): string {
		const idTokenString = sessionStorage.getItem(ID_TOKEN_USERID);
		if (idTokenString) {
		  const cdsid = sessionStorage.getItem(ID_TOKEN_USERID);
		  AuthService.loginID.next(cdsid)
		  return cdsid;
		}
		return '';
	  }

	/**
	 * Check user is logged in
	 */
	static isLoggedIn(): boolean {
		return moment().isBefore(moment(sessionStorage.getItem(TOKEN_EXPIRATION_TIME), 'X'));
	}

	storeJWT(userIdKeyId: string, jwtTokenKeyId: string, token: string): void {
		sessionStorage.setItem(userIdKeyId, atob(token.split('.')[1])); // only store payload section from JWT
		sessionStorage.setItem(jwtTokenKeyId, token);
	}

	storeTokenExpirationTime(token: string): void {
		const payload = atob(token.split('.')[1]);
		sessionStorage.setItem(TOKEN_EXPIRATION_TIME, JSON.parse(payload).exp);
	}

	retrieveJwtToken(): string {
		return sessionStorage.getItem(ID_TOKEN_JWT);
	}
}
