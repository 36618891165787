// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

:host ::ng-deep div .ui-dialog .ui-dialog-titlebar{
	border : 2px solid #A7A7A7;
}

:host ::ng-deep div .ui-dialog .ui-dialog-content{
	border : 2px solid #A7A7A7;
	background-color: darkgreen;
	color:white;
}

td[colspan]:hover {
	background: white;
	color: #1f7307;
	font-weight: bold;
}

.style1.warning{
	font-color: red;
}

.p-datatable .p-datatable-thead > tr > th {
	text-align: left;
	padding: 0px;
	border-width: 0 0 1px 0;
	font-weight: 600;
	color: #495057;
	background: #f8f9fa;
	transition: box-shadow 0.2s;
}

p-table {
	width: 100%;
	table-layout: fixed;
	display: block;
}

p-table td {
	overflow: auto; 
	white-space: pre-wrap; 
	word-wrap: break-word; 
	word-break: break-word;
	overflow-wrap: break-word;
	width: 15%;
}

p-table td:empty {
	background-color: #F5F5F5;
}

.spaced-table td {
	padding: 0 15px;
}

.centered-content {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.clickable-row {
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/app/dp-trend/dp-trend.component.css"],"names":[],"mappings":";;AAEA;CACC,0BAA0B;AAC3B;;AAEA;CACC,0BAA0B;CAC1B,2BAA2B;CAC3B,WAAW;AACZ;;AAEA;CACC,iBAAiB;CACjB,cAAc;CACd,iBAAiB;AAClB;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,gBAAgB;CAChB,YAAY;CACZ,uBAAuB;CACvB,gBAAgB;CAChB,cAAc;CACd,mBAAmB;CACnB,2BAA2B;AAC5B;;AAEA;CACC,WAAW;CACX,mBAAmB;CACnB,cAAc;AACf;;AAEA;CACC,cAAc;CACd,qBAAqB;CACrB,qBAAqB;CACrB,sBAAsB;CACtB,yBAAyB;CACzB,UAAU;AACX;;AAEA;CACC,yBAAyB;AAC1B;;AAEA;CACC,eAAe;AAChB;;AAEA;CACC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;CACnB,YAAY;AACb;;AAEA;CACC,eAAe;AAChB","sourcesContent":["\n\n:host ::ng-deep div .ui-dialog .ui-dialog-titlebar{\n\tborder : 2px solid #A7A7A7;\n}\n\n:host ::ng-deep div .ui-dialog .ui-dialog-content{\n\tborder : 2px solid #A7A7A7;\n\tbackground-color: darkgreen;\n\tcolor:white;\n}\n\ntd[colspan]:hover {\n\tbackground: white;\n\tcolor: #1f7307;\n\tfont-weight: bold;\n}\n\n.style1.warning{\n\tfont-color: red;\n}\n\n.p-datatable .p-datatable-thead > tr > th {\n\ttext-align: left;\n\tpadding: 0px;\n\tborder-width: 0 0 1px 0;\n\tfont-weight: 600;\n\tcolor: #495057;\n\tbackground: #f8f9fa;\n\ttransition: box-shadow 0.2s;\n}\n\np-table {\n\twidth: 100%;\n\ttable-layout: fixed;\n\tdisplay: block;\n}\n\np-table td {\n\toverflow: auto; \n\twhite-space: pre-wrap; \n\tword-wrap: break-word; \n\tword-break: break-word;\n\toverflow-wrap: break-word;\n\twidth: 15%;\n}\n\np-table td:empty {\n\tbackground-color: #F5F5F5;\n}\n\n.spaced-table td {\n\tpadding: 0 15px;\n}\n\n.centered-content {\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\theight: 100%;\n}\n\n.clickable-row {\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
