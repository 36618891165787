import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {environment} from '../../environments/environment';
// import {UserIdService} from '../services/user-id.service';

/**
 * This guard is used across all app routes that require live data,
 * determining if the auth token is valid before allowing navigation to occur
 */
@Injectable()
export class AuthorizationGuard  {

    /** Creates an instance of the AuthorizationGuard
     *
     * @param router used for navigation actions
     */
    constructor(private router: Router) {
    }

    /** the actual guard for routing. Uses the current token information to determine if the user needs to go through the login flow again
     *
     * @param route the snapshot of the current ActivatedRoute
     * @param state the snapshot of the current RouterState
     * @returns whether route can be activated or not
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // Store state url in sessionStorage for later redirect by callback component

        sessionStorage.setItem('redirectURL', state.url);
        return this.checkLogin();
    }

    /** Function used to determine if the current token is valid. Checks token expiration against the current timestamp
     * @returns whether or not the token is expired
     */
    isTokenExpired(): boolean {
        const epoch = Math.trunc(new Date().getTime() / 1000);
        let expEpoch = null;

        if (sessionStorage.getItem('tokenExp') && sessionStorage.getItem('tokenExp') !== 'null') {
            expEpoch = parseInt(sessionStorage.getItem('tokenExp'), 10);
            return (epoch >= expEpoch);
        } else {
            return true;
        }
    }

    /** Uses isTokenExpired() to determine if the user credentials should be cleared and the user forwarded to the login component
     * @returns for whether the user is "logged in" or not
     */
    checkLogin(): boolean {
        // Check if token is expired (or null)
        if (this.isTokenExpired()) {
            // Clear oauth session items
            sessionStorage.removeItem('tokenExp');
            sessionStorage.removeItem('tokenIssue');
            sessionStorage.removeItem('strAccessToken');
            sessionStorage.removeItem('encodedAccessToken');

            // Navigate to the login page with extras
            this.router.navigate(['/login']);

            return false; // guard complete and then router redirects to /login
        }
        return true;
    }
}
