import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ACCESS_TOKEN_JWT, ACCESS_TOKEN_USERID, ID_TOKEN_JWT, ID_TOKEN_USERID} from '../auth-service/auth-vars-constants';
import {AuthService} from '../auth-service/auth.service';

/**
 * OauthCallbackComponent - component for user's authentication
 */
@Component({
  selector: 'app-oauth-callback',
  template: `
    <h3>Redirecting...</h3>
  `,
  styles: []
})
export class OauthCallbackComponent implements OnInit {

  readonly idToken: string;
  readonly accessToken: string;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService) {
    const searchParams = new URLSearchParams(route.snapshot.fragment);
    this.idToken = searchParams.get('access_token');
    this.accessToken = searchParams.get('access_token');
  }

  /**
   * Verify & store OAuth tokens for autherized user
   */
  ngOnInit() {
    this.verifyAndStoreOAuthTokens();
  }

  /**
   * This method stores OAuth tokens for autherized user
   */
  private verifyAndStoreOAuthTokens() {
    if (this.accessToken != null &&
      this.idToken != null) {
      this.authService.storeJWT(ID_TOKEN_USERID, ID_TOKEN_JWT, this.idToken);
      this.authService.storeJWT(ACCESS_TOKEN_USERID, ACCESS_TOKEN_JWT, this.accessToken);
      this.authService.storeTokenExpirationTime(this.idToken);
      this.router.navigate([sessionStorage.getItem('redirectURL')])

    } else {
      // TODO: Set lint rule for alert, implement failure component
      alert('Problem authenticating');
    }
  }
}
