import {NgModule} from '@angular/core';
import {CommonModule, Location, LocationStrategy, HashLocationStrategy} from '@angular/common';
import {OauthCallbackComponent} from './oauth-callback.component';
import {AuthorizationGuard} from './authorization.guard';
import {OauthTokenGuard} from './oauth-token.guard';
import {LoginComponent} from './login.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        OauthCallbackComponent,
        LoginComponent
    ],
    providers: [
        AuthorizationGuard,
        OauthTokenGuard,
        Location,
    ]
})
export class OauthModule {}
