import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {DpAdminService} from '../services/dpAdmin/dp-admin.service';
import {AuthService} from '../auth-service/auth.service';

/**
 * AuthGuardIOS - service implement route guard for role - Anchor-IOS
 */
@Injectable({
	providedIn: 'root'
})
export class AuthGuardIos  {

	isAnchorios: boolean;
	constructor(private router: Router) {
	}

	/**
	 * This method check user is an ios anchor. If user is an ios anchor, navigation to a route will be allowed.
	 * If user is not an ios anchor, it direct user to home page
	 */
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (window.sessionStorage.getItem("anchor-ios") === "true" || window.sessionStorage.getItem("admin") === "true" ) {
			return true;
		} else {
			this.router.navigate(['']);
			return false;
		}
	}
}
