import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {DpTrendService} from '../services/dpTrend/dp-trend.service';
import {TrendData} from '../services/dpTrend/dptrend';
import {HttpClient} from '@angular/common/http';
import { Router } from '@angular/router';
import {formatDate} from '@angular/common';

@Component({
	templateUrl: './dp-trend.component.html',
	styleUrls: ['./dp-trend.component.css']
})
export class DpTrendComponent implements OnInit, OnDestroy {

	constructor(private dpTrendService: DpTrendService, private http: HttpClient, private router: Router) {
		this.dateRange = [
				{name: 'Last 24 hrs', no_of_days: 1},
				{name: 'Last 7 days', no_of_days: 7},
				{name: 'Last One month', no_of_days: 30}
		];
		this.tableDataMap = new Map<string, {}>();

	}
	trendDataList: TrendData[];
	tableDataMap: Map<string, {}>;
	tableDataList: any[];
	startDate: any;
	endDate: any;
	dateRange: object[];
	rows: number;
	displayDialog: boolean = false;
  selectedRow: any;
	cols: any[];

	ngOnInit() {
		this.cols = [
			{ field: 'task', header: 'Task' },
			{ field: 'failedClass', header: 'Failed Class' },
			{ field: 'failedTest', header: 'Failed Test' },
			{ field: 'reason', header: 'Reason' },
			{ field: 'region', header: 'App' },
			{ field: 'count', header: 'Count' }
	];
		this.getTrendData();
		this.adjustRows();
		this.tableDataList = [];
	}

	getTrendData(): void {
		this.dpTrendService.retrieveTrendData(this.startDate, this.endDate).subscribe(data => {
			this.trendDataList = data;
			this.tableDataMap.clear();
			this.trendDataList.forEach(element => {
				let key = `${element.task}-${element.failedClass}-${element.failedTest}-${element.reason}-${element.region}`;
				
				if (this.tableDataMap.has(key)) {
					this.tableDataMap.get(key)["count"] += 1
					if (!this.tableDataMap.get(key)["prs"].includes(element.prNumber)){
						this.tableDataMap.get(key)["prs"].push(element.prNumber);
					}
					if (!this.tableDataMap.get(key)["authors"].includes(element.author)) {
						this.tableDataMap.get(key)["authors"].push(element.author);
					}
				} 
				
				else {
					let tableData = {}
					tableData["task"] = element.task;
					tableData["failedClass"] = element.failedClass;
					tableData["failedTest"] = element.failedTest;
					tableData["reason"] = element.reason;
					tableData["region"] = element.region;
					tableData["count"] = 1;
					tableData["prs"] = [element.prNumber];
					tableData["authors"] = [element.author];
					this.tableDataMap.set(key, tableData);
				}
			});
			this.tableDataList = Array.from(this.tableDataMap.values());
		});
	}


	onChange(event) {
		this.startDate = new Date(new Date().getTime() - (event.value.no_of_days * 24 * 60 * 60 * 1000));
		this.endDate = new Date();
		this.startDate = formatDate(this.startDate, 'yyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'', 'en-US');
		this.endDate = formatDate(this.endDate, 'yyy-MM-dd\'T\'HH:mm:ss.SSS\'Z\'', 'en-US');
		this.getTrendData()
	}

	adjustRows() {
    this.rows = window.innerHeight < 400 ? 3 : window.innerHeight < 600 ? 4 : window.innerHeight < 830 ? 5 : window.innerHeight < 1000 ? 10 : 20;
  }

	@HostListener('window:resize')
  onResize() {
    this.adjustRows();
  }

	showDialog(row: any) {
    this.selectedRow = row;
    this.displayDialog = true;
  }

	ngOnDestroy() {
	}
}
