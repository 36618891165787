import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import { timeout } from 'rxjs/operators';
import {ConfirmationService} from 'primeng/api';
import {AuthService} from '../auth-service/auth.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  templateUrl: './api-testing.component.html',
	styleUrls: ['../app.component.scss',
            './api-testing.component.scss']
})

export class APITesting implements OnInit {

	hide: boolean = true;
	updateSuccess: BehaviorSubject<boolean> = new BehaviorSubject(true);
	status: string = null;
	secretKey: string;
	secretValue: string;
	messageText: string;
	response: any;
	subject = new Subject();
	searchText: string;
	keyList: Array<string> = [];

	constructor(private router: Router, private http:HttpClient, private confirmationService: ConfirmationService) {}

	ngOnInit() {
		this.updateSuccess.subscribe(val => {
			this.getKeys();
		})
	}

	password() {
    this.hide = !this.hide;
	}

	confirm() {
		this.response = this.checkSecret();
		this.subject.subscribe(
			response => {
				if (response['status'] === 1302) {
					this.messageText =  "A secret with the key <br></br><b>" + this.secretKey + "</b><br></br> already exists. Would you like to overwrite it?"
				} else if (response['status'] === 1303) {
					this.messageText =  "Do you wish to add the secret with the key: <br></br><b>" + this.secretKey + "</b>?"
				} else {
					this.status = "failure"
					return
				}
					this.confirmationService.confirm({
						message: this.messageText,
						accept: () => {
							this.updateSecret();
							this.clearInfo();
							}
						})
			})
		}

	clearInfo() {
		this.secretKey = '';
		this.secretValue = '';
	}

  checkSecret() {
		let my_url = environment.backend_url + "/api-testing/checksecret/" + this.secretKey
    this.http.get(my_url)
		.pipe()
		.subscribe(response => {
			this.subject.next(response)
		},
		error => {
			this.subject.next(error)
		});
	}

	getKeys() {
		let my_url = environment.backend_url + "/api-testing/getkeys"
    this.http.get<Array<string>>(my_url)
		.pipe()
		.subscribe(response => {
			this.keyList = response.sort();
		},
		error => {
			alert("Error pulling existing secret keys, please try again or contact ccdevops@ford.com");
			this.keyList = [];
		});
	}

	async updateSecret() : Promise<any> {
		let my_url = environment.backend_url + "/api-testing/addsecret"
		this.http.post(my_url, 
			{
				"secretKey": this.secretKey,
				"secretValue": this.secretValue,
				"cdsid": AuthService.retrieveCDSID()
		})
		.pipe(timeout(5000))
		.subscribe(
				val => {
						if (val['status'] === 1300) {
							this.status =  "success";
						} else if (val['status'] === 1301) {
							this.status =  "failure"
						} else {
							this.status = "failure"
						}
						this.updateSuccess.next(true);
				},
				error => {
					if  (error['status'] === 400){
						this.status = "bad request"
					} else {
						this.status = "failure"
					}
					this.updateSuccess.next(true);
				})
	}
}