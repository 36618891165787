import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dp-summary',
  templateUrl: './dp-summary.component.html',
  styleUrls: ['./dp-summary.component.scss']
})
export class DpSummaryComponent implements OnInit {

      constructor() { }
      ngOnInit() { }

}
