import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import { AuthService } from 'src/app/auth-service/auth.service';

@Injectable({
	providedIn: 'root'
})
export class DpAdminService {

	constructor(private http: HttpClient) { }
	static isAdmin = new BehaviorSubject<boolean>(false);
	static isAnchorios = new BehaviorSubject<boolean>(false);
	static isAnchorandroid = new BehaviorSubject<boolean>(false);
	loginID: string = "";
	adminStatus: any;
	static isAPITesting = new BehaviorSubject<boolean>(false);

	static assignRoles(data: Array<string> ) {
		data.forEach( (role) => {
			window.sessionStorage.setItem(role, 'true');
		});
		if (data.indexOf('anchor-android') >= 0) {
			this.isAnchorandroid.next(true);
		}
		if (data.indexOf('anchor-ios') >= 0) {
			this.isAnchorios.next(true);
		}
		if (data.indexOf('admin') >= 0) {
			this.isAdmin.next(true);
		}
		if (data.indexOf('apitesting') >= 0) {
			this.isAPITesting.next(true);
		}
	}

	checkAdminStatus() {
		this.loginID = AuthService.retrieveCDSID();
		if(this.loginID != "" && this.loginID != null){
			this.http.get<Array<string>>(`${environment.backend_url}` + '/dp-admin/roles/' + this.loginID)
				.subscribe(
				(val) => {
					DpAdminService.assignRoles(val);
				},
				response => {
					console.log('POST call in error', response);
				},
				() => {
					//POST completed successfully
				});
		}
	}

	ngOnInit () {
	}
}