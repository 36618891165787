import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {AppComponent} from './app.component';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {ToastModule} from 'primeng/toast';
import {SidebarModule} from 'primeng/sidebar';
import {CardModule} from 'primeng/card';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, MessageService, } from 'primeng/api';
import {AccordionModule} from 'primeng/accordion';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './home';
import { HeaderFooterComponent } from './header-footer/header-footer.component';
import { OauthModule } from './oauth/oauth.module';
import {TooltipModule} from 'primeng/tooltip';
import { BrowserNotSupportedComponent } from './browser-not-supported/browser-not-supported.component';
import { DpTrendComponent } from './dp-trend/dp-trend.component';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {ListboxModule} from 'primeng/listbox';
import { DpSummaryComponent } from './dp-summary/dp-summary.component';
import { IosOneOffBuildsComponent } from './ios-one-off-builds/ios-one-off-builds.component';
import {CalendarModule} from 'primeng/calendar';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {APITesting} from './api-testing/api-testing.component';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {SearchFilter} from './search-filter'
import { RouterLinkStubDirective } from '../testing/router-stubs';
import { RouterOutletStubDirective } from '../testing/router-stubs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AlphaBetaFailureTrendComponent } from './alpha-beta-failure-trend/alpha-beta-failure-trend.component';
import { NotFoundComponent } from './not-found/not-found.component';
import {environment} from '../environments/environment';
import {
  NgxAdfsModule,
  HttpRequestInterceptor,
  UserIdService
} from '@wame/ngx-adfs';

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		HeaderFooterComponent,
		BrowserNotSupportedComponent,
		DpTrendComponent,
		DpSummaryComponent,
		IosOneOffBuildsComponent,
		APITesting,
		SearchFilter,
		RouterLinkStubDirective,
    RouterOutletStubDirective,
    AlphaBetaFailureTrendComponent,
    NotFoundComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		FormsModule,
		HttpClientModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		SidebarModule,
		CardModule,
		MultiSelectModule,
		DropdownModule,
		ConfirmDialogModule,
		AccordionModule,
		MessagesModule,
		MessageModule,
		TooltipModule,
		TableModule,
		DialogModule,
		ListboxModule,
		CalendarModule,
		ProgressBarModule,
		ProgressSpinnerModule,
		DynamicDialogModule,
		MatListModule,
		OauthModule,
		ToastModule,
		ScrollingModule,
		NgxAdfsModule.forRoot({
			openidUrl: environment.openidUrl,
			openidClientId: environment.openidClientId,
			openidResource: environment.openidResource,
	})
	],
	providers: [MessageService, ConfirmationService, DialogService,
		UserIdService,
		{
				provide: HTTP_INTERCEPTORS,
				useClass: HttpRequestInterceptor,
				multi: true
		}],
	bootstrap: [AppComponent]
})
export class AppModule { }
