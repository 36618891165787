// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unsupported-browser-card {
  background-color: rgb(31, 115, 7);
  text-align: center;
  width: 50vw;
  margin-left: auto;
  margin-right: auto;
  padding: 5px;
  margin-top: 10vh;
  border-radius: 10px;
  box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.5);
  color: white;
}

.supported-browser-icons {
  display: flex;
  margin-left: 12vw;
  margin-left: auto;
  margin-right: auto;
}

.icon-div {
  margin-left: auto;
  margin-right: auto;
}

.browser-icon {
  height: 5vw;
  width: 5vw;
  margin: 1vw;
}

.browser-icon-ie {
  height: 6vw;
  width: 10vw;
  margin: 1vw;
}

.unsupported-browser-viewport {
  position: fixed;
  top: 70px;
  background-color: white;
  height: 70%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/browser-not-supported/browser-not-supported.component.scss"],"names":[],"mappings":"AAAA;EACI,iCAAA;EACA,kBAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,mBAAA;EACA,gDAAA;EACA,YAAA;AACJ;;AAEE;EACE,aAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AACE;EACE,iBAAA;EACA,kBAAA;AAEJ;;AACE;EACE,WAAA;EACA,UAAA;EACA,WAAA;AAEJ;;AACE;EACE,WAAA;EACA,WAAA;EACA,WAAA;AAEJ;;AAAE;EACE,eAAA;EACA,SAAA;EACA,uBAAA;EACA,WAAA;EACA,WAAA;AAGJ","sourcesContent":[".unsupported-browser-card{\n    background-color: rgb(31,115,7);\n    text-align: center;\n    width: 50vw;\n    margin-left: auto;\n    margin-right: auto;\n    padding: 5px;\n    margin-top: 10vh;\n    border-radius:10px;\n    box-shadow: 0px 10px 10px 3px rgba(0, 0, 0, 0.5);\n    color: white;\n  }\n  \n  .supported-browser-icons{\n    display: flex;\n    margin-left: 12vw;\n    margin-left: auto;\n    margin-right: auto;\n  }\n  .icon-div{\n    margin-left: auto;\n    margin-right: auto;\n  }\n  \n  .browser-icon{\n    height: 5vw;\n    width: 5vw;\n    margin: 1vw;\n  }\n  \n  .browser-icon-ie{\n    height: 6vw;\n    width: 10vw;\n    margin: 1vw;\n  }\n  .unsupported-browser-viewport{\n    position: fixed;\n    top: 70px;\n    background-color:white;\n    height: 70%;\n    width: 100%;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
