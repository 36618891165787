import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class AuthGuardAPITesting  {

	constructor(private router: Router) {
	}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (environment.apiTestingEnabled === true && (window.sessionStorage.getItem("apitesting") === "true" || window.sessionStorage.getItem("admin") === "true" )) {
			return true;
		} else {
			this.router.navigate(['']);
			return false;
		}
	}
}
