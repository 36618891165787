// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 50%;
  color: black;
}

.example-viewport {
  height: 200px;
  width: 100%;
  border-style: inset;
  border-width: 1px;
}

.example-alternate {
  background: rgba(127, 127, 127, 0.3);
}

.full-center-no-border {
  margin: auto;
  width: 100%;
  padding: 10px;
}

input[type=number], input[type=text], select, textarea {
  width: 100%;
  border: 1px solid #a7a7a7;
  border-radius: 3px;
  height: 34px;
}

label, legend {
  font-weight: 700;
  font-size: 1rem;
}

.center-no-border {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.center {
  margin: auto;
  width: 50%;
  border: 3px solid;
  padding: 10px;
}

.ui-confirmdialog-message {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/api-testing/api-testing.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,iBAAA;EACA,oBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,UAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,WAAA;EACD,mBAAA;EACC,iBAAA;AACF;;AAEA;EACE,oCAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,UAAA;AACF;;AAEA;EACE,YAAA;EACA,UAAA;EACA,iBAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;AACF","sourcesContent":["hr { \n  display: block;\n  margin-top: 0.5em;\n  margin-bottom: 0.5em;\n  margin-left: auto;\n  margin-right: auto;\n  border-style: inset;\n  border-width: 1px;\n  width: 50%;\n  color: black;\n} \n\n.example-viewport {\n  height: 200px;\n  width: 100%;\n\tborder-style: inset;\n  border-width: 1px;\n}\n\n.example-alternate {\n  background: rgba(127, 127, 127, 0.3);\n}\n\n.full-center-no-border {\n  margin: auto;\n  width: 100%;\n  padding: 10px;\n}\n\ninput[type=number], input[type=text], select, textarea {\n  width: 100%;\n  border: 1px solid #a7a7a7;\n  border-radius: 3px;\n  height: 34px;\n}\n\nlabel, legend {\n  font-weight: 700;\n  font-size: 1rem;\n}\n\n.center-no-border{\n  margin-left: auto;\n  margin-right: auto;\n  width: 50%;\n}\n\n.center {\n  margin: auto;\n  width: 50%;\n  border: 3px solid;\n  padding: 10px;\n}\n\n.ui-confirmdialog-message {\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
