import { Component, OnInit } from '@angular/core';
import { DpTrendService } from '../services/dpTrend/dp-trend.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'alpha-beta-failure-trend',
  templateUrl: './alpha-beta-failure-trend.component.html',
  styleUrls: ['./alpha-beta-failure-trend.component.scss']
})
export class AlphaBetaFailureTrendComponent implements OnInit {

  constructor(private dpTrendService: DpTrendService, private http: HttpClient, private router: Router) {
		this.dateRange = [
				{name: 'Last 24 hrs', no_of_days: 1},
				{name: 'Last 7 days', no_of_days: 7},
				{name: 'Last One month', no_of_days: 30}
		];
	}
	alphaBetaTrendDataList: any[];
	dateRange: object[];
  displayDetails: boolean = false;
  reasonDialogDisplay = false;

  details: any;
	ngOnInit() {
		this.getalphaBetaTrendData(30);
	}

	getalphaBetaTrendData(days): void {
      this.http.get( `${environment.backend_url}/dp-trial/alphabetafailures/${days}`).subscribe((data: any[]) => {
        for (let i = 0; i < data.length; i++) {
          data[i].date = new Date(data[i].date);
        }
        this.alphaBetaTrendDataList = data;
      })
	}


  showDetails(details) {
		if ( details != null) {
			this.displayDetails = true;
      this.reasonDialogDisplay = true;
			this.details = details;
		}
	}

	onChange(event) {
		this.getalphaBetaTrendData(event.value.no_of_days)
	}

}
