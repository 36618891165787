import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {TrendData} from './dptrend';

@Injectable({
  providedIn: 'root'
})
export class DpTrendService {

  constructor(private http: HttpClient) { }

	retrieveTrendData(startDate, endDate): Observable<any> {
		let trendDataObj  = {}
		trendDataObj["startDate"] = startDate;
		trendDataObj["endDate"] = endDate;

		const headers = { 'content-type': 'application/json'};
		const body= JSON.stringify(trendDataObj);
		return this.http.post(`${environment.backend_url}` + '/dp-trial/' + 'fetchTrendData' , body,{'headers':headers});

	}
}
